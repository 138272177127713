addEventListener('DOMContentLoaded', function() {
    'use strict';

    var forms = document.querySelectorAll('form[name=newsletterSubscription]');
    for (var i = 0; i < forms.length; i++) {
        initializeNewsletterForm(forms[i]);
    }

    function initializeNewsletterForm(form) {
        var textElement = document.createElement('p');
        form.parentNode.appendChild(textElement);

        function showSuccessMessage() {
            form.parentNode.removeChild(form.previousElementSibling);
            form.parentNode.removeChild(form);
            textElement.textContent = 'Newsletter-Anmeldung erfolgreich.';
        }

        function showErrorMessage() {
            textElement.textContent = 'Anmeldung fehlgeschlagen, bitte überprüfen Sie Ihre Eingabe.';
        }

        form.addEventListener('submit', function(event) {
            event.preventDefault();

            var request = new XMLHttpRequest();

            request.addEventListener('load', function(event) {
                try {
                    var data = JSON.parse(event.target.responseText);
                    if (data && data.success) {
                        showSuccessMessage();
                    } else {
                        showErrorMessage();
                    }
                } catch (e) {
                    showErrorMessage();
                }
            });

            request.addEventListener('error', function() {
                showErrorMessage();
            });

            request.open('POST', '?action=ajax\\SubscribeNewsletter');
            request.send(new FormData(form));
        });
    }
});
