addEventListener('DOMContentLoaded', function() {
    'use strict';

    var slideshowContainer = document.querySelector('.splide');
    if (slideshowContainer) {
        var splide = new Splide(slideshowContainer, {
            type: 'loop',
            autoplay: true,
            interval: 7000
        });

        splide.mount();
    }
});
